<template>
  <div class="login">
    <Layout class="layout">
      <LayoutContent class="layout-content">
    <div class="login-component-layout">
      <Form
          ref="formRef"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :model="formInfo"
          @submit="handleSubmit"
          @keydown.enter="handleSubmit">
        <FormItem
            label="手机号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="loginName"
        >
          <Input
              type="text" allow-clear
              v-model:value="formInfo.loginName"
          />

        </FormItem>
        <FormItem
            label="密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="password"
        >
          <Input
              type="password" allow-clear
              v-model:value="formInfo.password"
          />
        </FormItem>
        <FormItem
            class="text-left"
            :wrapper-col="{ offset: 4, span: 20 }"
        >
          <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              style="border-radius: 5px;"
          >登录</Button>

<!--          <Button-->
<!--              type="default"-->
<!--              @click="handleReset"-->
<!--              style="margin-left: 20px;"-->
<!--          >重置</Button>-->

        </FormItem>
        <div class="tiparea">
          <p>
            <router-link to="/forgotPassword">忘记密码</router-link>
            &nbsp;
            <router-link to="/register">注册</router-link>
          </p>
        </div>
      </Form>
    </div></LayoutContent>
      <LayoutFooter class="layout-footer">
        ©2021 Created by 都能印
      </LayoutFooter>
    </Layout>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import { login } from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
interface FormInfo {
  loginName: string;
  password:string;
}
export default defineComponent({
  name: 'Login',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      loginName:'',
      password:'',
    })
    const rules = ref({
      loginName:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
      ],
      password:[
        { required: true, message: '请输入密码', trigger: 'blur' },
      ]
    })
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)

    //登录
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{

        login({
          "user": JSON.stringify(formInfo.value),
        }).then((res)=>{
          // console.log(res)
          if(res.httpCode == 200){
            setToken(res.data.session)
            router.push('/');
          }
        })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    // 点击重置
    const handleReset = () => {
      formInfo.value.loginName = '';
      formInfo.value.password = '';
    }
    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      handleSubmit,
      handleReset
    }

  },
})

</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  position: relative;
  .layout {
    height: 100%;
    &-content {
      background-color: white;
    }
    &-footer {
      text-align: center;
    }
  }
  &-component-layout {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
    border-radius: 5px;
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    ::v-deep .ant-form-item-label > label::after {
      content: '';
    }
  }
  .tiparea {
    text-align: right;
    font-size: 12px;
    color: #333;
  }
  .tiparea p a {
    color: #409eff;
  }
}

</style>
