
import {
  defineComponent,
  ref
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import { login } from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
interface FormInfo {
  loginName: string;
  password:string;
}
export default defineComponent({
  name: 'Login',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      loginName:'',
      password:'',
    })
    const rules = ref({
      loginName:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
      ],
      password:[
        { required: true, message: '请输入密码', trigger: 'blur' },
      ]
    })
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)

    //登录
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{

        login({
          "user": JSON.stringify(formInfo.value),
        }).then((res)=>{
          // console.log(res)
          if(res.httpCode == 200){
            setToken(res.data.session)
            router.push('/');
          }
        })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    // 点击重置
    const handleReset = () => {
      formInfo.value.loginName = '';
      formInfo.value.password = '';
    }
    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      handleSubmit,
      handleReset
    }

  },
})

