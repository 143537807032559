import request from '@/axios/index'
export function login(data:any) {
  return request({
    url: '/system/login',
    method: 'post',
    params: data
  })
}
export function logout(data:any) {
  return request({
    url: '/system/logout',
    method: 'post',
    params: data
  })
}
//获取注册验证码信息
export function registerAuthImage(data:any) {
  return request({
    url: '/system/registerAuthImage',
    method: 'get',
    params: data
  })
}
//注册发送短信验证码
export function registerSendSms(data:any) {
  return request({
    url: '/system/registerSendSms',
    method: 'post',
    params: data
  })
}

//注册
export function register(data:any) {
  return request({
    url: '/system/register',
    method: 'post',
    params: data
  })
}
//获取忘记密码验证码信息
export function fwdAuthImage(data:any) {
  return request({
    url: '/system/fwdAuthImage',
    method: 'get',
    params: data
  })
}
// 忘记密码发送短信验证码
export function fwdSendSms(data:any) {
  return request({
    url: '/system/fwdSendSms',
    method: 'post',
    params: data
  })
}
// 忘记密码修改密码
export function fwdPassword(data:any) {
  return request({
    url: '/system/fwdPassword',
    method: 'post',
    params: data
  })
}

// 获取当前登录用户信息
export function getCurrentUser(data:any) {
  return request({
    url: '/system/getCurrentUser',
    method: 'post',
    params: data
  })
}