import axios from 'axios'
import type { AxiosResponse } from 'axios'

import { message,Modal} from 'ant-design-vue'

import {MutationTypes, useStore} from '@/store'
import {removeToken} from '@/utils/auth'
const store = useStore()
// create an axios instance
// console.log( window.g.ApiUrl)
const service = axios.create({
    headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
    transformRequest: [function (data) {
        if (typeof data === 'object') {
            let ret = []
            for (let it in data) {
                ret.push(encodeURIComponent(it) + '=' + encodeURIComponent(data[it]))
            }
            return ret.join('&')
        } else return data
    }],
    baseURL: process.env.NODE_ENV === 'production' ? '/apis' : '/apis',
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 8000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
      // do something before request is sent

      // if (store.getters.token) {
      //     // let each request carry token
      //     // ['X-Token'] is a custom headers key
      //     // please modify it according to the actual situation
      //     config.headers['X-Token'] =  getToken();
      // }
      return config
  },
  error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response: AxiosResponse<any>) => {
      const res = response.data
      // console.log(res)
      // if the custom code is not 20000, it is judged as an error.
      if (res.httpCode !== 200) {
          message.error(res.msg || res.data,5)

          // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
          if ( res.httpCode === 401 || res.httpCode === 403 || res.httpCode === 402 || res.httpCode === 50014) {
              // to re-login
              Modal.confirm({
                  title:'提示',
                  content:'您已注销，可以取消以留在此页，或重新登录',
                  okText:'重新登录',
                  cancelText:'取消',
                  onOk(){
                      removeToken();
                      window.location.reload()
                  }
              })
          }
          return Promise.reject(new Error(res.msg || res.data))
      } else {
          return res
      }
  },
  error => {
      // console.log('err' + error) // for debug
      message.error(error)
      // Message({
      //   message: error.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // return Promise.reject(error)
  }
)

export default service
